import React from "react"
// import Img from "gatsby-image"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import ArticlePreview from "../components/article-preview.js"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

import "./newsletter-post.module.scss"

const NewsletterTemplate = ({ data }) => {
  const postNewsletter = data && data.contentfulNewsletter
  const allArticlesPosts =
    (data && data.allContentfulArticle && data.allContentfulArticle.edges) || []
  const filtered = allArticlesPosts.filter(
    article =>
      article.node.key === postNewsletter.key &&
      article.node.isHighlight === false
  )

  const highlightedTemp =
    (data && data.highligtedArticle && data.highligtedArticle.edges) || []

  const highlighted = highlightedTemp.filter(
    article => article.node.key === postNewsletter.key
  )

  const url =
    (typeof window !== "undefined" && window.location.href) ||
    `https://mysha.pe/newsletter/${postNewsletter.slug}`

  const issOdd = id => {
    return id % 2
  }

  //title, body, type-color, articles filtered according to key
  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <SEO
          title={postNewsletter.title}
          meta={[
            {
              property: "og:title",
              content: postNewsletter.title,
            },
            // {
            //   property: 'og:image',
            //   content: postNewsletter.heroImage.src
            // },
            {
              property: "og:description",
              content: postNewsletter.subtitle,
            },
            {
              property: "og:url",
              content: url,
            },
          ]}
        />

        <Container styleName="content-wrapper">
          <div
            styleName={`box ${
              postNewsletter.type[0] === "about-you" ? "about-you" : ""
            } ${postNewsletter.type[0] === "the-people" ? "the-people" : ""} ${
              postNewsletter.type[0] === "the-facitilies"
                ? "the-facitilies"
                : ""
            } ${postNewsletter.type[0] === "the-company" ? "the-company" : ""}`}
          >
            <div styleName="heading">
              <span style={{ fontWeight: 700 }}>SHAPE</span> | Newsletter
            </div>
            <div
              styleName="content"
              dangerouslySetInnerHTML={{
                __html: postNewsletter.body.childMarkdownRemark.html,
              }}
            />
          </div>
          <div styleName="top-row-wrapper">
            <div styleName="top-row-left">
              {highlighted[0] && (
                <ArticlePreview post={highlighted[0].node} highlighted={true} />
              )}
            </div>
            <div styleName="top-row-right">
              {filtered[0] && (
                <div styleName="top-row-right-post">
                  <ArticlePreview post={filtered[0].node} />
                </div>
              )}
              {filtered[1] && (
                <div styleName="top-row-right-post">
                  <ArticlePreview post={filtered[1].node} />
                </div>
              )}
            </div>
          </div>
          <div styleName="filtered-row-wrapper">
            {filtered.map((post, idx) => {
              if (idx > 1) {
                return (
                  <div
                    id={idx}
                    styleName="filter-post-wrap"
                    style={{
                      padding: issOdd(idx)
                        ? "0px 0px 0px 15px"
                        : "0px 15px 0px 0px",
                    }}
                  >
                    <ArticlePreview post={post.node} />
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </Container>
        <CTA isFreeReport={true} />
      </div>
    </Layout>
  )
}
export default NewsletterTemplate

export const pageQuery = graphql`
  query NewsletterBySlug($slug: String!) {
    contentfulNewsletter(slug: { eq: $slug }) {
      title
      tags
      subtitle
      publishDate(formatString: "MMMM D")
      heroImage {
        fluid(maxWidth: 1300) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      slug
      key
      type
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    highligtedArticle: allContentfulArticle(
      sort: { fields: [publishDate], order: [DESC] }
      limit: 1
      filter: { isHighlight: { eq: true }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...ArticlePreviewFields
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            fluid(quality: 95) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    allContentfulArticle(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...ArticlePreviewFields
        }
      }
    }
  }
`

export const ArticlePreviewFields = graphql`
  fragment ArticlePreviewFields on ContentfulArticle {
    title
    subtitle
    slug
    publishDate(formatString: "MMMM D, YYYY")
    tags
    type
    key
    isHighlight
    heroImage {
      sizes(maxWidth: 622, maxHeight: 241, quality: 100) {
        ...GatsbyContentfulSizes_withWebp
      }
    }
  }
`
