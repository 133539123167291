import React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import TruncateMarkup from "react-truncate-markup"

import Card from "react-bootstrap/Card"

import "./article-preview.module.scss"

const ArticlePreview = ({ post, highlighted, ...otherProps }) => {
  return (
    <Link to={`/article/${post.slug}`} className="card" styleName="wrapper">
      {post.heroImage && (post.heroImage.sizes || post.heroImage.fluid) && (
        // <div styleName={`${highlighted ? "highlightedImg" : "filteredImg"}`}>
        <div>
          {highlighted ? (
            <Img
              className="card-img-top"
              styleName="img highlighted"
              title={post.title}
              fluid={post.heroImage.fluid}
            />
          ) : (
            <Img
              className="card-img-top"
              styleName="img"
              title={post.title}
              sizes={post.heroImage.sizes}
            />
          )}
        </div>
      )}
      <Card.Body
        styleName="body"
        style={{ height: highlighted ? "164px" : "128px" }}
      >
        <Card.Title styleName="title">
          <TruncateMarkup lines={2}>
            <h5 styleName={`${highlighted ? "highlightedTitle" : ""}`}>
              {post.title}
            </h5>
          </TruncateMarkup>
        </Card.Title>
        <p styleName="subtitle">
          <TruncateMarkup lines={2}>
            <span styleName={`${highlighted ? "highlightedSubtitle" : ""}`}>
              {post.subtitle}
            </span>
          </TruncateMarkup>
        </p>
      </Card.Body>
    </Link>
  )
}

export default ArticlePreview
